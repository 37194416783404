.modal-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 9999;

    .img-modal-container {
        // max-height: 75%;
        position: relative;
        max-width: 600px;

        img {
            // cur
            width: 100%;
        }

        .close-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            border: none;
            background-color: transparent;
            transition: 200ms all ease-in-out;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                transform: scale(1.5);
            }
        }
    }
}
