.image.dragging {
    cursor: grabbing;
    cursor: -webkit-grabbing;
}

.carousel-image {
    object-fit: cover;
    height: 100%;
    max-height: 650px;
    width: 100%;
}
