@import "../../variables.scss";
.landing-page {
    margin: 0 !important;
    padding: 0 !important;
    // width: 100%;
    max-width: none !important;
    .hero-image-container {
        // background-color: lightgrey;
        position: relative;
        padding-top: 115px;
        @media screen and (max-width: 500px) {
            min-height: 85vh;
        }
    }

    .bottom-hero-image-text-container {
        padding-top: 115px;
        // :not(:last-child) {
        pointer-events: none;
        // }
        width: 100%;
        position: absolute;
        bottom: 50px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .hero-image-entrance-text {
            border: 40px;
            border-radius: 20px;
            width: 400px;
            height: 200px;
            background-color: rgba($color: #000000, $alpha: 0.4);
            padding: 1rem;
            color: $headerbackground;
            max-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h1 {
                margin: 0;
                text-align: center;
            }
            p {
                text-align: center;
            }

            @media screen and (max-width: 800px) {
                max-width: 75%;
                padding: 2rem;
            }
            @media screen and (max-width: 500px) {
                background: none;
                max-width: 100%;
                padding: 1rem;
            }
        }
        @media screen and (max-width: 500px) {
            position: relative;
            padding-top: 60px;
            .hero-image-entrance-text {
                color: black;
            }
        }

        .link-to-products {
            pointer-events: auto;
            @media screen and (max-width: 500px) {
                &:hover {
                    color: $darkgreenish;
                }
            }
            &:hover {
                color: $lightgreenish;
            }
        }
    }
}
