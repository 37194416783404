@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

h2,
h3,
h4,
h5 {
    margin: 0;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: inherit;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

dfn {
    font-style: italic;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details,
menu {
    display: block;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "";
    clear: both;
    height: 0;
}

html {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

li,
ul,
a {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    color: inherit;
}

body {
    background-color: $greenbackground;
}

.wrapper {
    color: $fontcolour;
    background-color: $greenbackground;
    min-height: 85vh;
    max-width: 1350px;
    margin: 0 auto;
    border-radius: 5px;
    // border: 1px solid rgb(208, 208, 208);
    padding-top: 115px; //site in progress banner + normal padding (75px)
    padding-left: 20px;
    padding-right: 20px;
}

.login-button {
    color: $darkgreenish;
    border-radius: 5px;
    margin-top: 25px;
    width: 100%;
    height: 40px;
    background-color: $lightgreenish;
    border: 1px solid $midgreenish;
    transition: all 100ms ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        border: 1px solid $darkgreenish;
        background-color: $midgreenish;
    }

    &:active {
        border: 1px solid $darkgreenish;
        background-color: $lightgreyish;
    }
}

.product-colours-container {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .product-colour {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 2px solid $black;
        margin: 0 5px;
        cursor: pointer;
        transition: 200ms;

        &.large-colour {
            margin: 0;
            border: 1px solid $black;
            height: 25px;
            width: 25px;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .large-product-colour {
        margin: 0 5px;
        height: 35px;
        width: 35px;
        border: 2px solid $heather-charcoal;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 100ms ease-in-out;

        &:hover,
        &:active,
        &:focus {
            transform: scale(1.1);
            border: 2px solid $black;
        }
    }

    .black-heather {
        background-color: $black-heather;
    }

    .dark-grey-heather {
        background-color: $dark-grey-heather;
    }

    .heather-dust {
        background-color: $heather-dust;
    }

    .olive {
        background-color: $olive;
    }

    .white {
        background-color: $white;
    }

    .black {
        background-color: $black;
    }

    .soft-cream {
        background-color: $soft-cream;
    }

    .charcoal-heather {
        background-color: $charcoal-heather;
    }

    .military-green {
        background-color: $military-green;
    }

    .carbon-grey {
        background-color: $carbon-grey;
    }

    .navy-blazer {
        background-color: $navy-blazer;
    }

    .navy {
        background-color: $navy;
    }

    .sport-grey {
        background-color: $sport-grey;
    }

    // TWOTONES
    .black-white {
        background: linear-gradient(270deg, $black 50%, $white 50%);
    }

    .heather-denim-navy {
        background: linear-gradient(270deg, $heather-denim 50%, $navy-two 50%);
    }

    .heather-grey-heather-charcoal {
        background: linear-gradient(270deg, $heather-grey 50%, $heather-charcoal 50%);
    }

    .white-heather-charcoal {
        background: linear-gradient(270deg, $white 50%, $heather-charcoal 50%);
    }
}

.private-terms-span-hover:hover {
    text-decoration: underline;
}
