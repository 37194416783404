@import "../../variables.scss";
.my-acount-parent-container {
    max-width: 800px !important;
    display: flex;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        .my-account-left-container {
            flex: 0 0 auto !important;
        }
    }
    .my-account-left-container {
        margin: 20px 10px 0 0;
        flex: 1 1 25%;
        width: 100%;
        @media screen and (max-width: 500px) {
            flex: 0 0 auto;
            flex-direction: column;
        }
        .my-account-nav-container {
            .my-account-link-item a {
                transition: all 200ms ease-in-out;
                border-radius: 5px;
                margin-top: 5px;
                min-width: 200px;
                height: 40px;
                padding: 10px;
                border: 2px solid #dddddd;
                margin: 10px 0px;
                outline: none;
                padding: 8px;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .my-account-link-item:not(.selected) a:hover {
                border: 1px solid rgba(141, 171, 127, 1);
                box-shadow: 0 0 5px rgba(141, 171, 127, 1);
            }
            .selected a {
                border: 2px solid rgba(141, 171, 127, 1);
                box-shadow: 0 0 5px rgba(141, 171, 127, 1);
            }
        }
    }

    .my-account-right-container {
        margin: 25px 0 0 10px;
        flex: 1 1 75%;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #dddddd;
        padding: 20px;

        .orders-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .rotate {
                transform: rotate(90deg);
            }

            // .faq-question:after {
            //     content: "\2039";
            //     margin-right: 10px;
            //     font-size: 45px;
            //     font-weight: bold;
            //     rotate: 90deg;
            // }

            // .faq-question.active:after {
            //     content: "\203A";
            //     font-size: 45px;
            // }
            .order-item-container {
                width: 100%;
                border: 1px solid gray;
                border-radius: 3px;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                .fl-row {
                    cursor: pointer;
                    padding: 10px;
                    background-color: $headerbackground;
                    display: flex;
                    flex-direction: row;
                }
                .order-details-parent-container {
                    display: flex;
                    flex-direction: column;
                    flex-basis: 70%;
                }
                .order-image-container {
                    flex-basis: 20%;
                    img {
                        width: 100%;
                        max-width: 85px;
                    }
                }
                .shipping-information {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border-top: 1px solid gray;
                    border-bottom: 1px dashed gray;
                    .shipping-title {
                        font-size: 16px;
                    }
                    .shipping-information-specifics {
                        display: flex;
                        justify-content: center;
                        div:first-of-type {
                            padding-right: 10px;
                        }
                        div:last-of-type {
                            padding-left: 10px;
                        }
                    }
                }

                .chevron-container {
                    text-align: right;
                    flex-basis: 10%;
                }
                .order-item-image {
                    img {
                        width: 100%;
                        max-width: 85px;
                    }
                }
                //
                .order-details-container {
                    display: flex;
                    flex-direction: column;
                    .order-container-top {
                        display: flex;
                        justify-content: space-between;
                    }

                    .order-name {
                        font-size: 18px;
                        font-weight: 700;
                        font-style: italic;
                    }

                    .order-id {
                        font-size: 12px;
                    }
                }
                .order-specific-parent-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    .order-specific-container {
                        padding: 5px;
                        display: flex;
                        flex-direction: column;

                        .order-item-name {
                        }

                        .order-item-information-container {
                        }

                        .order-item-information {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        .info-message {
            padding: 10px;
            border-radius: 5px;
            margin-top: 23px;
            opacity: 90%;
            transition: all 250ms linear;
        }

        .info-message-hidden {
            opacity: 0%;
            transition: all 250ms linear;
        }

        .error-message {
            background-color: darkred;
            color: whitesmoke;
        }

        .success-message {
            background-color: $greenish;
            color: whitesmoke;
        }

        .save-info-btn {
            margin-top: 25px;
            color: $darkgreenish;
            cursor: pointer;
            border-radius: 5px;
            padding: 3px;
            width: 100px;
            height: 40px;
            background-color: $lightgreenish;
            border: 1px solid $midgreenish;
            transition: all 100ms ease-in-out;
            &:hover,
            &:focus {
                border: 1px solid $darkgreenish;
                background-color: $midgreenish;
            }
        }

        p {
            margin: 0;
        }
        .change-info {
            // text-decoration: underline;
            color: gray;
            border: 1px solid gray;
            border-radius: 20px;
            font-size: 10px;
            cursor: pointer;
            padding: 5px;
            transition: 100ms all;
            &:hover,
            &:focus {
                border: 1px solid $darkgreenish;
                color: white;
                background-color: $midgreenish;
            }
        }

        .user-info-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(200, 200, 200, 0.5);
        }
        .user-info-text-container {
            display: flex;
            justify-content: space-between;
        }

        .my-account-header {
            margin-bottom: 25px;
        }

        .login-form {
            label {
                margin-top: 10px;
            }

            .individual-input-container svg {
                top: 30px;
            }
        }
    }
}
