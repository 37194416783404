// COLOURS
$lightblueish: #587b7f;
$darkgreenish: #1f320d;
$greenish: #335215;
$midgreenish: #708d63;
$lightgreenish: #8dab7f;
$whitegreen: #a2d38b;
$greyish: #e8e9eb;
$lightgreyish: #adadad;
$greenbackground: #f4f4f4;
$headerbackground: #eeeee9;
$fontcolour: #323232;

// CLOTH COLOURS
$dark-grey-heather: #3e3c3d;
$black-heather: #0b0b0b;
$heather-dust: #e8d7ca;
$olive: #5b642f;
$soft-cream: #e7d4c0;
$white: #ffffff;
$black: #0a0a0a;
$charcoal-heather: #3a3a38;
$military-green: #5c4f32;
$carbon-grey: #d2cec6;
$navy-blazer: #171f2c;
$navy: #1a2330;
$navy-two: #252334;
$sport-grey: #c4c0be;
$heather-denim: #2b345a;
$heather-grey: #a3a5a9;
$heather-charcoal: #4a4c4d;

$lightboxshadow: 0 1px 2px hsla(0, 0%, 0%, 0.1), 0 1px 4px hsla(0, 0%, 0%, 0.1),
    0 2px 8px hsla(0, 0%, 0%, 0.1);
$lightgreenboxshadow: 0 1px 2px hsla(106, 32%, 8%, 0.1),
    0 1px 4px hsla(106, 32%, 8%, 0.1), 0 2px 8px hsla(106, 32%, 8%, 0.1);

:export {
    fontcolour: $fontcolour;
    headerbackground: $headerbackground;
    greenbackground: $greenbackground;
    lightblueish: $lightblueish;
    darkgreenish: $darkgreenish;
    midgreenish: $midgreenish;
    greenish: $greenish;
    lightgreenish: $lightgreenish;
    greyish: $greyish;
    lightgreyish: $lightgreyish;
    whitegreen: $whitegreen;
    lightboxshadow: $lightboxshadow;
    lightgreenboxshadow: $lightgreenboxshadow;
    dark-grey-heather: $dark-grey-heather;
    black-heather: $black-heather;
    heather-dust: $heather-dust;
    olive: $olive;
    white: $white;
    black: $black;
    soft-cream: $soft-cream;
    charcoal-heather: $charcoal-heather;
    military-green: $military-green;
    carbon-grey: $carbon-grey;
    navy-blazer: $navy-blazer;
    navy: $navy;
    sport-grey: $sport-grey;
    navy-two: $navy-two;
    sport-grey: $sport-grey;
    heather-denim: $heather-denim;
    heather-grey: $heather-grey;
    heather-charcoal: $heather-charcoal;
}
