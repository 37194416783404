@import "../../variables.scss";
h1 {
    margin: auto 0;
    text-align: center;
}

.products-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 25px;

    .filters-container {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        width: 100%;

        margin-top: 25px;

        .sort-by-container {
            display: flex;
            align-items: center;
            .product-sort-by {
                height: 35px;
                padding: 5px;
                margin-left: 5px;
            }
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .sort-by-container {
                padding: 15px 0;
            }
        }
    }

    .shop-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .product-item-container {
            margin: 20px 40px 0px;
            .product-info-container {
                margin: 0 auto;
                max-width: 150px;
                p {
                    margin: 0px;
                }
                .product-price {
                    border-bottom: 1px solid #e1e1e1;
                    padding: 5px 0 15px 0;
                }
            }

            .product-item {
                border-radius: 5px;
                width: 198px;
                height: 198px;
                box-shadow: $lightboxshadow;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
