@import "../../variables.scss";

.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .contact-text {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // width: 100%;
        // p {
        margin: 0 auto;
        max-width: 700px;
        // }
        p {
            margin: 15px;
        }
    }
    .email-us {
        border-bottom: 1px solid $fontcolour;
    }

    .business-hours-container {
        display: flex;
        justify-content: center;
        width: 100%;
        .business-hours-content {
            background-color: $headerbackground;
            padding: 50px;
            width: 500px;
        }
    }
}
