.individual-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    label {
        margin-top: 25px;
        font-size: 15px;
        align-self: flex-start;
    }
    input {
        transition: all 200ms ease-in-out;
        border-radius: 5px;
        margin-top: 5px;
        min-width: 300px;
        height: 40px;
        padding: 10px;
        border: 1px solid #dddddd;
        &.err {
            border: 1px solid rgb(139, 90, 90);
            &:focus {
                border: 1px solid rgb(171, 127, 127);
                box-shadow: 0 0 5px rgb(171, 127, 127);
            }
        }
        margin: 5px 1px 3px 0px;
        outline: none;
        padding: 8px;
        font-size: 12px;
        &:focus {
            border: 1px solid rgba(141, 171, 127, 1);
            box-shadow: 0 0 5px rgba(141, 171, 127, 1);
            margin: 5px 1px 3px 0px;
            padding: 8px;
        }
        @media screen and (max-width: 400px) {
            min-width: 200px;
        }
    }
    .input-err-span {
        color: rgb(119, 42, 42);
        max-width: 300px;
        font-size: 12px;
    }
    svg {
        transition: all 200ms ease-in-out;
        color: lightgray !important;
        position: absolute;
        top: 55px;
        right: 10px;
        z-index: 1;
        &:hover,
        &:focus {
            color: rgba(141, 171, 127, 1);
        }
    }
}
