@import "../../variables.scss";

header {
    width: 100%;
    height: 115px; //site in progress banner + normal height (75px)
    background-color: $headerbackground;
    position: fixed;
    box-shadow: $lightboxshadow;
    z-index: 700;
    color: $fontcolour;

    nav {
        z-index: 701;
        margin: 0 auto;
        max-width: 1350px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 840px) {
            width: 100%;
        }

        .home-link {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 200ms ease-in-out;

            &:hover {
                color: $greenish;

                .logo-text {
                    // font-family: "Libre Baskerville";
                    border-top: 3px solid $greenish;
                }
            }

            .logo {
                // margin-left: 20px;
                width: 60px;
                height: 60px;
            }

            .logo-text {
                text-align: center;
                vertical-align: middle;
                line-height: 70px;
                margin-left: 5px;
                border-top: 3px solid transparent;
                @media screen and (max-width: 350px) {
                    font-size: 20px;
                }
            }
        }

        .nav-menu-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 150px;
            position: relative;

            .login-button {
                margin-top: 0;
                width: 75px;
            }

            .relative {
                cursor: pointer;
                position: relative;
            }

            .shopping-bag-counter {
                text-align: center;
                vertical-align: middle;
                line-height: 14px;

                font-size: 10px;
                color: $headerbackground;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: $fontcolour;
            }
        }

        .main-nav {
            display: flex;
            justify-content: center;
            align-items: center;

            .link-li {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                border-top: 3px solid transparent;
                transition: all 200ms ease-in-out;

                &:hover {
                    color: $greenish;
                    border-top: 3px solid $greenish;
                }

                a {
                    width: 100%;
                    font-weight: 700;
                    padding: 0 20px;
                }
            }
        }

        .burger-container {
            height: 5em;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 16px 0px 16px 0;

            .burger-menu {
                height: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                cursor: pointer;

                .burger-bar {
                    width: 30px;
                    height: 4px;
                    background-color: $fontcolour;
                    border-radius: 15px;
                }

                .burger-bar.clicked:nth-child(1) {
                    transform: translate(0px, 10px) rotate(50deg);
                    transition: ease-out 0.25s;
                }

                .burger-bar.clicked:nth-child(2) {
                    transform: scale(0.1);
                    transition: ease-out 0.25s;
                }

                .burger-bar.clicked:nth-child(3) {
                    transform: translate(0px, -10px) rotate(130deg);
                    transition: ease-out 0.25s;
                }

                .burger-bar.unclicked {
                    transform: rotate(0) translate(0);
                    transition: cubic-bezier(0.125, 0.75, 0.5, 1.1) 0.25s;
                }
            }
        }

        .menu-container {
            // width: 30%;
            min-width: 300px;
            position: absolute;
            top: 100px;
            right: 0;

            // @media screen and (max-width: 840px) {
            //     width: 70%;
            // }

            @media screen and (max-width: 350px) {
                min-width: 250px;
            }

            .menu {
                background-color: $headerbackground;
                margin: 0 auto;
                width: 90%;
                // height: 40vh;
                z-index: 999;
                border-radius: 10px;
                box-shadow: $lightboxshadow;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .link-items {
                    flex-direction: column;
                    margin: 0;
                    padding: 20px;

                    li {
                        width: 98%;
                        margin: 5px;

                        a {
                            text-align: left;
                            font-size: 16px;
                            padding: 5px 0;
                            font-weight: 400;
                            width: 100%;
                            text-decoration: underline;
                            height: 100%;
                        }
                        transition: all 200ms ease-in-out;

                        &:hover {
                            color: $greenish;
                            // border-top: 3px solid $greenish;
                        }
                    }
                }

                .login-signup-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;

                    a {
                        margin: 0 0 20px 25px;
                    }
                }

                &.shopping-bag-menu {
                    max-height: 80vh;
                    text-align: center;
                    padding: 25px;
                    min-width: 400px;
                    @media screen and (max-width: 400px) {
                        min-width: 250px;
                    }
                    overflow-y: auto;
                }
            }
            .hidden {
                visibility: hidden;
                opacity: 0;
            }

            .visible {
                visibility: visible;

                animation: slide 100ms ease-in;
                transform-origin: top right;
            }
        }
    }
}

@keyframes slide {
    from {
        transform: translateY(-24px) scale(0.1);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        transform: scale(1);
        opacity: 1;
    }
}

.site-in-progress-banner {
    width: 100%;
    background-color: rgb(81, 101, 89);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        color: #fff;
    }
}
.background {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 600;
    background-color: rgb(92, 92, 92, 0.5);
}

.background {
    &.hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.visible {
        visibility: visible;
        opacity: 100;
    }
}
