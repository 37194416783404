@import "../../variables.scss";

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-body-text {
        color: $fontcolour;
        border-top: 1px solid rgba(200, 200, 200, 0.5);
        border-bottom: 1px solid rgba(200, 200, 200, 0.5);
        padding: 20px 0;
        font-size: 12px;
    }
}
