@import "../../variables.scss";

.product-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;

    .product-image-container {
        display: flex;
        flex-direction: column;
        margin: 50px 50px 0 50px;
        max-width: 700px;

        .image-carousel img {
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
        }

        .image-carousel img.active {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            position: relative;
        }

        .image-carousel img.left {
            transform: translateX(-20%);
        }

        .image-carousel img.right {
            transform: translateX(20%);
        }

        .product-main-image {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            max-height: 500px;
            border-radius: 20px;

            .img-directions-container {
                position: absolute;
                z-index: 9;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 25%;
                    z-index: 10;
                    background-color: rgba($color: #000000, $alpha: 0.1);
                    &:first-of-type {
                        border-radius: 0 5px 5px 0;
                    }
                    &:last-of-type {
                        border-radius: 5px 0 0 5px;
                    }
                    &:hover,
                    &:focus {
                        transition: all 200ms ease-in-out;
                        background-color: rgba($color: #000000, $alpha: 0.2);
                        .chevron {
                            color: black;
                        }
                    }
                }

                .circle {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: grey;
                    margin: 0 5px 10px;
                    opacity: 80%;
                    transition: all 200ms ease-in-out;
                    cursor: pointer;

                    &:hover,
                    &.active {
                        background-color: black;
                    }
                }
            }

            .img-container {
                max-width: 500px;
                img {
                    border-radius: 10px;
                    width: 100%;
                    // border-radius: 10px;
                    // box-shadow: $lightboxshadow;
                }
            }
        }

        .product-carousel-container {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            overflow: hidden;
            flex-wrap: wrap;
            max-width: 500px;
            margin: 10px auto 0;

            .product-carousel-item {
                padding: 0;
                max-width: 100px;
                margin: 20px 5px;
                border: none;
                background-color: transparent;

                img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    box-shadow: $lightboxshadow;
                    cursor: pointer;
                    width: 100%;
                }

                .active {
                    border: 2px solid $black;
                    transition: all 200ms ease-in-out;
                }

                .inactive {
                    border: 2px solid transparent;

                    &:hover {
                        border: 2px solid $black;
                    }
                }
            }
        }
    }

    .product-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 50px 0 0 0;
        min-width: 300px;

        @media screen and (max-width: 540px) {
            align-items: center;
            margin: 0 15px;
            min-width: 0px;
            width: 100%;
        }

        @media screen and (max-width: 1275px) {
            align-items: center;
            // flex-direction: row;
            margin: 0 15px;
            min-width: 0px;
            width: 100%;
        }

        .product-name {
            overflow-wrap: break-word;
            text-align: center;
        }

        .product-price {
            margin: 10px 0;
            font-weight: 400;
        }

        .product-inputs-container {
            .product-colours-container {
                justify-content: flex-start;
                @media screen and (max-width: 1275px) {
                    justify-content: center;
                }
            }

            .select-container {
                @media screen and (max-width: 1275px) {
                    justify-content: center;
                    h4 {
                        text-align: center;
                    }
                }
            }

            .product-quantity-container {
                width: 100%;
                display: flex;
                align-items: center;

                flex-wrap: wrap;

                h4 {
                    width: 100%;
                    font-size: 16px;
                    padding-bottom: 10px;
                }

                @media screen and (max-width: 1275px) {
                    align-items: center;
                    margin: 0 auto;
                    width: 100%;
                    justify-content: center;

                    h4 {
                        text-align: center;
                    }
                }

                .product-quantity-input-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .plus-btn,
                    .neg-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $fontcolour;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                        transition: all 100ms ease-in-out;
                        svg {
                            path {
                                color: $fontcolour;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            transform: scale(1.1);
                            color: $black;
                            // border: 2px solid $black;
                            svg {
                                path {
                                    color: $black;
                                }
                            }
                        }
                    }

                    .quantity-input {
                        width: 40px;
                        padding: 8px;
                        margin: 0 10px;
                        text-align: center;
                    }
                }
            }

            .select-container {
                margin: 10px 0;
                display: flex;
                align-items: center;
                // justify-content: flex-end;
                flex-wrap: wrap;
                // width: 100%;

                h4 {
                    width: 100%;
                    font-size: 16px;
                    padding-bottom: 10px;
                }

                .standard-select {
                    height: 35px;
                    width: 150px;
                    padding: 5px;
                    // margin-left: 5px;
                }
            }
        }

        .add-to-bag-btn {
            color: $darkgreenish;
            border-radius: 5px;
            margin-top: 25px;
            width: 100%;
            height: 40px;
            background-color: $lightgreenish;
            border: 1px solid $midgreenish;
            transition: all 100ms ease-in-out;
            cursor: pointer;

            &:hover,
            &:focus {
                border: 1px solid $darkgreenish;
                background-color: $midgreenish;
            }

            &:active {
                border: 1px solid $darkgreenish;
                background-color: $lightgreyish;
            }
        }
    }
}
