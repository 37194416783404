@import "../../variables.scss";
.login-container {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-child-container {
        background-color: white;
        border-radius: 5px;
        box-shadow: $lightboxshadow;
        form,
        .successfully-logged-in-container {
            padding: 25px 25px 0 25px;
            .home-link {
                // height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .logo {
                    width: 60px;
                    height: 60px;
                }
                .logo-text {
                    text-align: center;
                    vertical-align: middle;
                    line-height: 50px;
                    margin-left: 5px;
                    border-top: 3px solid transparent;
                }
            }

            .input-container {
                color: $darkgreenish;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .info-message {
                padding: 10px;
                border-radius: 5px;
                margin-top: 23px;
                opacity: 90%;
                transition: all 250ms linear;
            }

            .info-message-hidden {
                opacity: 0%;
                transition: all 250ms linear;
            }

            .error-message {
                background-color: darkred;
                color: whitesmoke;
            }

            .success-message {
                background-color: $greenish;
                color: whitesmoke;
            }

            .logout-msg {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .sign-up-container {
            display: flex;
            justify-content: center;
            .sign-up-text-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                .sign-up-btn,
                .login-in-btn {
                    color: $darkgreenish;
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 3px;
                    width: 100px;
                    // height: 40px;
                    height: 30px;
                    font-size: 14px;
                    background-color: $lightgreenish;
                    border: 1px solid $midgreenish;
                    transition: all 100ms ease-in-out;
                    &:hover,
                    &:focus {
                        border: 1px solid $darkgreenish;
                        background-color: $midgreenish;
                    }
                }
                .sign-up-btn {
                }

                span {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
