@import "../../variables.scss";
h3,
h4 {
    text-align: left;
}
.add-to-bag-btn {
    color: $darkgreenish;
    border-radius: 5px;
    margin-top: 25px;
    width: 100%;
    max-width: 300px;
    height: 40px;
    background-color: $lightgreenish;
    border: 1px solid $midgreenish;
    transition: all 100ms ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        border: 1px solid $darkgreenish;
        background-color: $midgreenish;
    }

    &:active {
        border: 1px solid $darkgreenish;
        background-color: $lightgreyish;
    }
}

.shopping-bag-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border-top: 1px solid rgba(200, 200, 200, 0.5);
    padding: 10px 0;
    &:last-of-type {
        border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    }
    .shopping-bag-item-image {
        width: 25%;
        img {
            width: 100%;
            max-width: 85px;
        }
    }
    .shopping-bag-item-info {
        padding: 0 20px;
        height: 100%;
        width: 60%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        // .shopping-bag-item-title {

        // }
    }
    .shopping-bag-item-price {
        height: 100%;
        width: 15%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
        .trash-bin-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid rgb(100, 100, 100);
            margin-bottom: 25px;
            padding: 3px;
            cursor: pointer;
            &:hover,
            &:focus,
            &:active {
                transform: scale(1.1);
                border: 2px solid $fontcolour;
                transition: 200ms all ease-in-out;
                svg {
                    color: $fontcolour;
                    transition: 100ms all ease-in-out;
                }
            }
            svg {
                color: rgb(100, 100, 100);
            }
        }
    }
    p {
        margin: 0;
    }
}

.empty-bag-container {
    p {
        text-align: left;
    }
    .link {
        text-decoration: underline;
    }
    .link:hover {
        color: black;
    }
}

.subtotal-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.checkout-btn {
    color: $darkgreenish;
    border-radius: 5px;
    margin-top: 25px;
    width: 100%;
    height: 40px;
    background-color: $lightgreenish;
    border: 1px solid $midgreenish;
    transition: all 100ms ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        border: 1px solid $darkgreenish;
        background-color: $midgreenish;
    }

    &:active {
        border: 1px solid $darkgreenish;
        background-color: $lightgreyish;
    }
}
