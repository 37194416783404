.faq-container {
    h2 {
        margin-top: 25px;
        text-align: center;
    }
    // margin: 50px auto;
    max-width: 800px !important;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
}

.faq {
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    // border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    padding: 20px 0;
    cursor: pointer;
    border-left: 2px solid transparent;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 2px solid #333;
}

.faq-question:after {
    content: "+";
    margin-right: 10px;
    font-size: 24px;
    font-weight: bold;
}

.faq-question.active:after {
    content: "-";
}

@media (max-width: 600px) {
    .faq-container {
        font-size: 14px;
    }
}
