@import "../../variables.scss";

.loader-product-item-container {
    width: 150px;
    margin: 20px 40px 0px;

    .loader-product-info-container {
        margin: 0 auto;
        max-width: 150px;
        .loader-product-price,
        .loader-product-name {
            animation: preloader 2s linear infinite alternate;
            border-radius: 5px;
            box-shadow: $lightboxshadow;
            width: 150px;
            height: 15px;
            margin: 5px 0 0 0;
        }

        .loader-product-price {
            width: 85px;
        }
    }

    .loader-product-item {
        animation: preloader 2s linear infinite alternate;
        border-radius: 5px;
        width: 198px;
        height: 198px;

        box-shadow: $lightboxshadow;
        overflow: hidden;
    }

    .loader-product-colours-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;
        .loader-product-colour {
            animation: preloader 2s linear infinite alternate;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            box-shadow: $lightboxshadow;
            margin: 0 5px;
        }
    }
}

@keyframes preloader {
    0% {
        background-color: hsl(0, 0%, 98%);
    }

    100% {
        background-color: hsl(0, 0%, 90%);
    }
}
